/**
 * Component classes registered by plugins.
 *
 */

 @import 'print.css';

 @import 'tables.css';
 
 @import 'prism.css';
 
 @import 'tailwindcss/components';
 
 @import url('https://fonts.googleapis.com/css2?family=Archivo:ital,wght@0,100..900;1,100..900&family=Saira+Stencil+One&display=swap');
 
 .cstm_breadcrumbs_holder {
     padding: 28px 0 16px;
 }
 .cstm_breadcrumbs_holder nav ol li {
     font-family: "Inter", sans-serif !important;
     font-size: 12px !important;
     color: #1d2939;
     text-transform: capitalize;
 }
 .cstm_breadcrumbs_holder nav ol li a {
     font-family: "Inter", sans-serif !important;
     font-size: 12px !important;
     color: #667085;
 }
 .main_page_heading {
     font-family: "Archivo", sans-serif !important;
     font-size: 24px !important;
     font-weight: 600 !important;
     color: #1d2939;
 }
 .main_page_heading .extra_sub_heading {
     font-family: "Inter", sans-serif !important;
     font-size: 14px !important;
     font-weight: 600 !important;
     color: #1d2939;
     padding-left: 4px;
 }
 .sub_main_heading_holder {
     font-family: "Archivo", sans-serif !important;
     font-size: 18px !important;
     font-weight: 600 !important;
     color: #1d2939 !important;
 }
 .sub_main_heading_holder + .sub_txt,
 .sub_content_holder {
     font-family: "Inter", sans-serif !important;
     font-size: 14px !important;
     font-weight: 400 !important;
     color: #667085;
 }
 .thin_scrollbar_holder {
     overflow: auto;
 }
 .thin_scrollbar_holder::-webkit-scrollbar {
     width: 8px;
 }
 .thin_scrollbar_holder::-webkit-scrollbar-track {
     background-color: #f0f0f0;
     border-radius: 20px;
 }
 .thin_scrollbar_holder::-webkit-scrollbar-thumb {
     background-color: #a2a3a3;
     border-radius: 20px;
 }
 .primary_maroon_btn {
     background-color: #4b1f4d !important;
     color: white !important;
     font-size: 12px !important;
     font-style: normal;
     font-weight: 600 !important;
     text-transform:none !important;
     font-family: "Inter", sans-serif !important;
     border: 1px solid #562658 !important;
     display: flex;
     align-items: center;
     border-radius: 4px !important;
     padding: 9px 16px !important;
     box-shadow: none !important;
 }
 .primary_maroon_btn .icon {
     font-size: 16px;
     padding-right: 4px;
 }
 .primary_maroon_outlined_btn {
     border: 1px solid #562658 !important;
     background-color: #F8ECFB !important;
     color: #562658 !important;
     font-size: 12px !important;
     font-style: normal;
     font-weight: 600 !important;
     text-transform:none !important;
     font-family: "Inter", sans-serif !important;
     display: flex;
     align-items: center;
     border-radius: 4px !important;
     padding: 9px 16px !important;
     box-shadow: none !important;
 }
 .primary_maroon_outlined_btn.mr-10 {
     margin-right: 10px !important;
 }
 .primary_maroon_outlined_btn i.icon {
     color: #562658;
     font-size: 16px;
     padding-right: 4px;
 }
 .icon_primary_btn {
     padding: 6px 7px !important;
 }
 .icon_primary_btn .icon {
     color: #fff !important;
     padding-right: 0;
 }
 .each_cat_card {
     width: calc(20% - 10px);
 }
 .cstm_drawer_holder {
     z-index: 1300 !important;
 }
 .cstm_drawer_holder .heading_holder {
     width: 100%;
     display: flex;
     justify-content: space-between;
     align-items: center;
     position: absolute;
     left: 0;
     top: 0;
     padding: 15px 20px;
 }
 .cstm_drawer_holder .heading_holder .heading {
     font-family: "Archivo", sans-serif;
     font-weight: 600;
     font-size: 20px;
     color: #1d2939;
     margin-bottom: 0;
 }
 .cstm_drawer_holder .cstm_wizard_holder {
     margin-top: 62px;
 }
 .cstm_drawer_holder .cstm_wizard_holder .box_holder {
    display: flex;
    flex-direction: column;
    /* height: 100vh; */
    max-height: calc(100vh - 140px);
    overflow: auto;
 }
 .cstm_drawer_holder .cstm_wizard_holder input {
     font-size: 14px !important;
 }
 .cstm_drawer_holder .MuiDrawer-paper {
     overflow-y: hidden;
 }
 .cstm_drawer_holder .cstm_wizard_holder .main_content_holder {
     /* height: calc(100vh - 100px); */
     height: 100%;
     overflow: auto;
 }
 .cstm_drawer_holder .cstm_wizard_holder .main_content_holder .main_heading {
     font-family: "Inter", sans-serif !important;
     font-size: 16px;
     font-weight: 600;
     padding: 28px 24px 12px 24px;
     background: #fff;
 }
 .cstm_drawer_holder .cstm_wizard_holder .main_content_holder .content_holder {
     /* height: calc(100% - 168px);
     overflow: auto; */
     padding: 0 24px 24px 24px;
 }
 .cstm_drawer_holder .cstm_wizard_holder .main_content_holder .content_holder .box_holder {
    max-height: 100%;
    overflow: hidden;
 }
 .cstm_drawer_holder .cstm_wizard_holder .main_content_holder .content_holder .box_holder .grid_container {
     background: #f9fafb;
     padding: 12px;
 }
 .cstm_drawer_holder .cstm_wizard_holder .main_content_holder .content_holder .box_holder:nth-child(2) .grid_container {
     margin-top: 15px;
 }
 .cstm_drawer_holder .cstm_wizard_holder .main_content_holder .content_holder .box_holder:last-child .grid_container + .action_holder .add_another_holder {
     display: inline-block;
 }
 .cstm_drawer_holder .cstm_wizard_holder .main_content_holder .content_holder .box_holder .grid_container + .action_holder .remove_holder {
     display: inline-block;
     padding-bottom: 20px;
 }
 .cstm_drawer_holder .cstm_wizard_holder .main_content_holder .content_holder .box_holder .grid_container + .action_holder .add_another_holder {
     margin-bottom: 20px;
 }
 .cstm_drawer_holder .cstm_wizard_holder .main_content_holder .content_holder .box_holder .grid_container + .action_holder .remove_holder i {
     display: inline-block;
     color: red;
     font-size: 20px;
 }
 .cstm_drawer_holder .cstm_wizard_holder .stepper_holder {
     flex-wrap: wrap;
     padding: 20px 24px 0 24px;
 }
 .cstm_drawer_holder .cstm_wizard_holder .stepper_holder .each_step_holder {
     padding: 0;
     margin: 10px 0;
 }
 .cstm_drawer_holder .cstm_wizard_holder .stepper_holder .each_step_holder > button.MuiStepButton-horizontal {
     padding: 0 16px;
 }
 .cstm_drawer_holder .cstm_wizard_holder .stepper_holder .each_step_holder + .MuiStepConnector-horizontal {
     max-width: 20px;
 }
 .cstm_drawer_holder .cstm_wizard_holder .stepper_holder .each_step_holder + .MuiStepConnector-horizontal .MuiStepConnector-line {
     border: none;
     padding: 0 10px;
     position: relative;
 }
 .cstm_drawer_holder .cstm_wizard_holder .stepper_holder .each_step_holder + .MuiStepConnector-horizontal .MuiStepConnector-line::before {
     content: '\003E';
     position: absolute;
     left: 50%;
     top: 50%;
     transform: translate(-50%, -50%);
     color: #98a2b3;
     font-size: 20px;
 }
 .cstm_drawer_holder .cstm_wizard_holder .stepper_holder .each_step_holder button .MuiStepLabel-iconContainer {
     display: none;
 }
 .cstm_drawer_holder .cstm_wizard_holder .main_content_holder .btn_holder {
     display: flex;
     justify-content: center;
     width: 100%;
     background: #fff;
     padding: 14px;
     position: absolute;
     bottom: 0;
     left: 0;
 }
 .cstm_drawer_holder .cstm_wizard_holder .main_content_holder .btn_holder button {
     width: 100%;
     padding: 12px 16px;
     font-family: "Inter", sans-serif !important;
     font-weight: 500;
     font-size: 12px;
     border-radius: 4px;
     margin: 0 6px;
 }
 .cstm_drawer_holder .cstm_wizard_holder .main_content_holder .btn_holder button.back_btn {
     background-color: #F8ECFB !important;
     color: #562658 !important;
     font-size: 12px !important;
     font-style: normal;
     font-weight: 500 !important;
     text-transform:none !important;
     font-family: "Inter", sans-serif !important;
     border: 1px solid #562658 !important;
     display: flex;
     align-items: center;
     border-radius: 4px !important;
     padding: 9px 16px !important;
     box-shadow: none !important;
 }
 .cstm_drawer_holder .cstm_wizard_holder .main_content_holder .btn_holder button.next_btn {
     max-width: 372px;
     background-color: #4b1f4d !important;
     color: white !important;
     font-size: 12px !important;
     font-style: normal;
     font-weight: 500 !important;
     text-transform:none !important;
     font-family: "Inter", sans-serif !important;
     border: 1px solid #562658 !important;
     display: flex;
     align-items: center;
     border-radius: 4px !important;
     padding: 9px 16px !important;
     box-shadow: none !important;
 }
 .cstm_drawer_holder .cstm_wizard_holder .stepper_holder .each_step_holder button .MuiStepLabel-labelContainer .MuiStepLabel-label {
     display: inline-block;
     background: transparent;
     color: #1d2939;
     border-radius: 4px;
     padding: 4px 8px;
     font-family: "Inter", sans-serif;
     font-size: 14px;
     font-weight: 500;
 }
 .cstm_drawer_holder .cstm_wizard_holder .stepper_holder .each_step_holder button .MuiStepLabel-labelContainer .MuiStepLabel-label.Mui-active,
 .cstm_drawer_holder .cstm_wizard_holder .stepper_holder .each_step_holder button .MuiStepLabel-labelContainer .MuiStepLabel-label.Mui-completed {
     background: #fdcccb;
     color: #1d2939 !important;
 }
 .date_pickr button svg {
     font-size: 20px;
 }
 .date_pickr_box .status_text + .MuiTextField-root {
     width: 100%;
 }
 .link_type_btn {
     font-family: "Inter", sans-serif !important;
     font-size: 12px !important;
     font-weight: 500 !important;
     background: transparent !important;
     color: #562658 !important;
     text-transform: capitalize !important;
 }
 .required_span {
     font-size: 12px;
     font-weight: 500;
     color: #f04438;
 }
 .upload_imgBox {
     text-align: center;
 }
 .upload_imgBox .icon_holder .material-icons {
     font-size: 25px;
 }
 .upload_imgBox .file_info_content {
     display: inline-block;
     font-family: "Inter", sans-serif !important;
     font-size: 12px !important;
     font-weight: 500 !important;
     color: #667085;
 }
 .upload_imgBox .file_info_content span {
     color: #562658;
 }
 .cstm_drawer_holder .progressbar_holder {
     width: 100%;
     position: absolute;
     left: 0;
     top: 62px;
     background: #ebedf1;
 }
 .cstm_drawer_holder .progressbar_holder .progress {
     height: 1.5px;
     background: #f76e6a;
     width: 0;
     transition: all 0.5s ease-in-out;
 }
 .canvas_holder {
     overflow: initial;
 }
 .canvas_holder canvas {
     width: 100% !important;
     object-fit: cover;
     border-radius: 16px !important;
 }
 .canvas_holder .mapboxgl-ctrl-bottom-left {
     display: none;
 }
 .map_outer_holder {
     padding-bottom: 16px;
     /* margin-bottom: 36px; */
     /* border-bottom: 1px solid #EAECF0; */
 }
 .map_acc_holder {
     position: relative;
     background: #fff;
     border-radius: 16px;
     box-shadow: 0px 24px 48px -12px rgba(16, 24, 40, 0.04);
 }
 .map_acc_holder .main_heading {
     font-family: "Inter", sans-serif;
     font-size: 16px;
     font-weight: 600;
     margin-bottom: 0;
     padding: 16px 20px;
     background: #fff;
     border-radius: 16px;
     box-shadow: 0px 24px 48px -12px rgba(16, 24, 40, 0.04);
 }
 .apexcharts-xaxis-label {
     color: #667085;
     fill: #667085;
     font-family: "Inter", sans-serif !important;
     font-size: 10px;
     font-style: normal;
     font-weight: 500;
     line-height: 16px; /* 160% */
 }
 .custom_apex_tooltip {
     display: flex;
     align-items: center;
     padding: 8px;
     border-radius: 4px;
     background: #101828;
     box-shadow: 0px 17.82px 35.64px -8.91px rgba(16, 24, 40, 0.12);
 }
 .custom_apex_tooltip .circle {
     width: 10px;
     height: 10px;
     border-radius: 100%;
     margin-right: 5px;
 }
 .custom_apex_tooltip .square {
     width: 10px;
     height: 10px;
     /* border-radius: 100%; */
     margin-right: 5px;
 }
 .custom_apex_tooltip .square + span {
     color: #fff;
 }
 
 .custom_apex_tooltip .circle + span {
     color: #fff;
 }
 .sub_list_holder a.fuse-list-item {
     display: flex;
     align-items: center;
     padding: 8px 16px;
     margin-bottom: 4px;
     color: #667085;
 }
 .sub_list_holder a.fuse-list-item .fuse-list-item-icon {
     display: flex;
     color: #98A2B3;
     font-size: 16px;
 }
 .sub_list_holder a.fuse-list-item .fuse-list-item-text {
     margin: 0;
 }
 .sub_list_holder a.fuse-list-item .fuse-list-item-text .fuse-list-item-text-primary {
     display: inline-block;
     white-space: normal;
     color: #667085;
     font-family: "Inter", sans-serif;
     font-size: 12px;
     font-style: normal;
     font-weight: 500;
 }
 .sub_list_holder a.fuse-list-item.active .fuse-list-item-text .fuse-list-item-text-primary,
 .sub_list_holder a.fuse-list-item.active .fuse-list-item-icon {
     color: #1D2939;
 }
 .sub_list_holder .main_heading {
     color: #1D2939;
     font-family: "Inter", sans-serif;
     font-size: 14px;
     font-style: normal;
     font-weight: 500;
     line-height: 20px;
     padding: 4px 16px;
     padding-right: 32px;
     margin-bottom: 8px;
 }
 #fuse-navbar-side-panel {
     border-right: 1px solid #EAECF0;
 }
 #fuse-navbar-panel {
     width: auto;
     min-width: 220px;
     max-width: 220px;
     background: #fff;
     border-right: 1px solid #EAECF0;
 }
 #fuse-navbar-panel .close_holder {
     position: absolute;
     top: 32px;
     right: 12px;
     cursor: pointer;
     z-index: 1;
 }
 #fuse-navbar-panel .close_holder + .sub_list_outer_holder {
     padding: 32px 0;
 }
 .main_navbar_holder {
     padding: 0;
 }
 .main_navbar_holder .fuse-list-item {
     max-width: 70px;
     padding: 12px 0;
 }
 .ag-theme-alpine .ag-row-selected,
 .ag-theme-alpine .ag-row-animation .ag-row.ag-after-created.ag-row-selected:hover,
 .ag-theme-alpine .ag-row-animation .ag-row.ag-after-created:hover {
     background-color: rgba(33, 150, 243, 0.3) !important;
 }
 .cstm_dropwdown_holder {
     padding: 12px 0;
 }
 .cstm_dropwdown_holder .each_holder {
     display: flex;
     flex-wrap: wrap;
     align-items: center;
     font-family: "Inter", sans-serif !important;
     font-size: 12px;
     font-style: normal;
     font-weight: 500;
     line-height: 16px;
     padding: 6px 12px;
     cursor: pointer;
 }
 .cstm_dropwdown_holder .each_holder i.ti {
     font-size: 16px;
     padding-right: 12px;
 }
 .ag-theme-alpine .ag-body-viewport::-webkit-scrollbar,
 .userlisting_dropdown::-webkit-scrollbar,
 .ag-body-horizontal-scroll-viewport::-webkit-scrollbar,
 .thin_horizontal_scrollbar::-webkit-scrollbar ,
 .thinscrollbar_holder::-webkit-scrollbar {
     width: 8px;
     height: 8px !important;
 }
 /* .ag-theme-alpine .ag-body-viewport:hover::-webkit-scrollbar {
     width: 8px;
 } */
 .ag-theme-alpine .ag-body-viewport::-webkit-scrollbar-track,
 .userlisting_dropdown::-webkit-scrollbar-track,
 .ag-body-horizontal-scroll-viewport::-webkit-scrollbar-track,
 .thin_horizontal_scrollbar::-webkit-scrollbar-track,
 .thinscrollbar_holder::-webkit-scrollbar-track {
     background-color: #fff;
     border-radius: 8px;
 }
 .ag-theme-alpine .ag-body-viewport::-webkit-scrollbar-thumb,
 .userlisting_dropdown::-webkit-scrollbar-thumb,
 .ag-body-horizontal-scroll-viewport::-webkit-scrollbar-thumb,
 .thin_horizontal_scrollbar::-webkit-scrollbar-thumb,
 .thinscrollbar_holder::-webkit-scrollbar-thumb {
     background-color: #EAECF0;
     border-radius: 8px;
 }
 .ag-header-row.ag-header-row-column .ag-react-container {
     width: 100%;
 }
 .ag-header-row.ag-header-row-column .ag-react-container .search_input {
     width: calc(100% - 25px);
 }
 .ag-header-row.ag-header-row-column .ag-react-container .datepickr_holder {
     display: flex;
     flex-wrap: wrap;
 }
 .ag-header-row.ag-header-row-column .ag-react-container .datepickr_holder input {
     padding: 6px;
     font-size: 12px;
 }
 .ag-header-row.ag-header-row-column .ag-react-container .datepickr_holder fieldset {
     border: none;
 }
 .ag-header-row.ag-header-row-column .ag-react-container .search_input::placeholder,
 .ag-header-row.ag-header-row-column .ag-react-container .datepickr_holder input::placeholder {
     font-size: 12px;
     font-weight: 400;
     color: #98a2b3;
     opacity: 1;
 }
 .sort_icon_holder {
     display: inline-block;
 }
 .sort_icon_holder .icon_holder {
     display: inline-flex;
     justify-content: center;
     width: 9px;
     font-size: 18px;
     color: #98a2b3;
 }
 .white_card_holder {
     background: #fff;
     padding: 32px;
     border-radius: 16px;
     box-shadow: 0 4px 44px 0 rgba(0, 0, 0, 0.04);
     margin-bottom: 12px;
 }
 .profile_section_holder {
     display: flex;
     flex-wrap: wrap;
     gap: 24px;
 }
 .profile_section_holder .left_holder {
     width: 325px;
 }
 .profile_section_holder .left_holder .white_card_holder {
     padding: 32px 28px;
 }
 .profile_section_holder .right_holder {
     width: calc(100% - 349px);
 }
 .profile_dropddown_holder {
     min-width: 76px;
     padding: 12px;
 }
 .profile_dropddown_holder .anchor_holder,
 .profile_dropddown_holder li {
     color: #1D2939;
     font-family: "Inter", sans-serif;
     font-size: 12px;
     font-style: normal;
     font-weight: 500;
     padding: 8px;
 }
 .profile_dropddown_holder .anchor_holder > div,
 .profile_dropddown_holder li > div {
     margin: 0;
 }
 .profile_dropddown_holder .anchor_holder.red_txt {
     color: #F04438;
 }
 .cstm_tabs_holder {
     display: inline-block;
     background: #f2f4f7;
     border-radius: 8px;
     padding: 8px 12px;
     margin-bottom: 40px;
     min-height: auto;
 }
 .cstm_tabs_holder .tabs_main_holder {
     min-height: auto;
 }
 .cstm_tabs_holder .tabs_main_holder .MuiTabs-flexContainer {
     flex-wrap: wrapgrey_table_holder;
 }
 .cstm_tabs_holder .tabs_main_holder .each_tab_holder {
     min-width: auto;
     max-width: auto;
     font-family: "Inter", sans-serif;
     font-size: 12px;
     font-weight: 500;
     color: #1d2939;
     padding: 8px 16px;
     border-radius: 8px;
     text-transform: capitalize;
     min-height: auto;
     text-decoration: none;
 }
 .cstm_tabs_holder .tabs_main_holder .each_tab_holder:hover {
     text-decoration: none;
 }
 .cstm_tabs_holder .tabs_main_holder .each_tab_holder.Mui-selected {
     background: #1d2939;
     color: #fff;
 }
 .cstm_tabs_holder .tabs_main_holder .MuiTabs-indicator {
     display: none;
 }
 .form_label_input {
     font-family: "Inter", sans-serif !important;
     font-size: 12px !important;
     font-weight: 500 !important;
     transform: scale(1) !important;
     margin-bottom: 8px !important;
     color: #1d2939 !important;
 }
 .form_input_holder input {
     font-family: "Inter", sans-serif !important;
     font-size: 14px !important;
     font-weight: 400 !important;
     padding: 10px 16px;
     border-color: #f2f4f7;
 }
 .form_input_holder fieldset {
     border-color: #f2f4f7 !important;
 }
 .form_input_holder.autocomplete_holder input {
     padding: 1px 7px !important;
 }
 .user_profileImg {
     display: flex;
     flex-wrap: wrap;
     justify-content: space-between;
     align-items: flex-start;
 }
 .user_profileImg .remove_btn,
 .danger_btn {
     font-family: "Inter", sans-serif !important;
     font-size: 12px !important;
     font-weight: 500 !important;
     background: #fef3f2 !important;
     color: #F04438 !important;
     border: 1px solid #F04438 !important;
     padding: 8px 12px;
     border-radius: 4px;
 }
 .user_profileImg .img_holder {
     width: 127px;
     height: 127px;
     border-radius: 100%;
     background: #fff;
     position: relative;
 }
 .user_profileImg .img_holder img {
     width: 100%;
     height: 100%;
     border-radius: 100%;
     object-fit: contain;
 }
 .user_profileImg .img_holder .input_btn_holder {
     position: absolute;
     bottom: 0;
     right: 0;
     cursor: pointer;
 }
 /* .user_profileImg .img_holder .input_btn_holder .input_file {
     position: absolute;
     z-index: 1;
     width: 40px;
     height: 40px;
     opacity: 0;
     cursor: pointer;
 } */
 .user_profileImg .img_holder .input_btn_holder .icon_holder,
 .user_profileImg .img_holder .input_btn_holder input[type="file"]#avatar-input {
     width: 40px;
     height: 40px;
     border-radius: 100%;
     background: #fff;
     box-shadow: 0 4px 24px 0 rgba(0, 0, 0, 0.08);
     display: inline-flex;
     justify-content: center;
     align-items: center;
 }
 .user_profileImg .img_holder .input_btn_holder input[type="file"]#avatar-input {
     position: absolute;
     z-index: 10;
     opacity: 0;
     cursor: pointer;
 }
 .user_profileImg .img_holder .input_btn_holder .icon_holder i {
     color: #667085;
     font-size: 18px;
     cursor: pointer;
 }
 .profile_details_holder .each_holder {
     margin: 16px 0;
 }
 .profile_details_holder .each_holder .label_holder {
     font-family: "Inter", sans-serif;
     font-size: 12px;
     font-weight: 500;
     color: #667085;
     margin-bottom: 2px;
 }
 .profile_details_holder .each_holder .info_holder {
     font-family: "Inter", sans-serif;
     font-size: 14px;
     font-weight: 500;
     color: #1d2939;
 }
 .font_24_heading {
     color: #1d2939;
     font-size: 24px !important;
     font-weight: 600 !important;
     line-height: 32px !important;
     font-family: "Archivo", sans-serif !important;
     margin-bottom: 10px;
 }
 .primary_btn,
 .react_dropzone_holder .dzu-submitButtonContainer .dzu-submitButton {
     background: #562658 !important;
     color: #fff !important;
     font-family: "Inter", sans-serif !important;
     font-size: 12px !important;
     font-weight: 500 !important;
     border: 1px solid #562658 !important;
     padding: 12px 16px !important;
     border-radius: 4px !important;
     text-transform: capitalize !important;
 }
 .button i.icon {
     font-weight: 600;
     padding-right: 4px;
 }
 .primary_btn.disabled {
     background: #EAECF0 !important;
     color: #98A2B3 !important;
     pointer-events: none !important;
 }
 .primary_btn.small_padding {
     padding: 8px 16px !important;
 }
 .danger_btn {
     padding: 12px 16px !important;
     border-radius: 4px !important;
     text-transform: capitalize !important;
 }
 .secondary_btn {
     background: #EAECF0 !important;
     color: #1D2939 !important;
     font-family: "Inter", sans-serif !important;
     font-size: 12px !important;
     font-weight: 500 !important;
     border: 1px solid #D0D5DD !important;
     padding: 9px 16px !important;
     border-radius: 4px !important;
     text-transform: capitalize !important;
 }
 .secondary_btn.py-4 {
     padding: 4px 16px !important;
 }
 .secondary_btn i.icon {
    color: #1D2939;
    font-size: 16px;
    padding-right: 4px;
}
 .cstm_badge_holder {
     display: inline-block;
     background: #F0F6FE;
     color: #3468DC;
     font-family: "Inter", sans-serif !important;
     font-size: 12px !important;
     font-weight: 500 !important;
     border: 1px solid #9BC4F5;
     border-radius: 16px;
     margin: 4px;
     padding: 3px 16px;
     line-height: 16px;
     text-transform: capitalize;
     max-width: 170px;
     white-space: nowrap;
     overflow: hidden;
     text-overflow: ellipsis;
 }
 .cstm_black_border_badge {
     display: inline-flex;
     align-items: center;
     padding: 3px 8px;
     border-color: #000;
     background: transparent;
     color: #000;
 }
 .cstm_badge_holder.enabled,
 .cstm_badge_holder.active,
 .cstm_badge_holder.low {
     background: #ECFDF3;
     color: #027A48;
     border-color: #6CE9A6;
 }
 .cstm_badge_holder.high {
     background: #FFFAEB;
     color: #F79009;
     border-color: #FEC84B;
 }
 .cstm_badge_holder.disabled,
 .cstm_badge_holder.no {
     background: #F9FAFB;
     color: #667085;
     border-color: #D0D5DD;
 }
 .cstm_badge_holder.expiring {
    background: #FEF3F2;
    color: #F04438;
    border: 1px solid #FDA29B;
 }
 .cstm_black_border_badge .dot_holder {
     display: inline-block;
     width: 8px;
     height: 8px;
     border-radius: 100%;
     background: #000;
     margin-right: 4px;
 }
 .cstm_black_border_badge.active .dot_holder {
     background: #12B76A;
 }
 .cstm_black_border_badge.broken .dot_holder {
     background: #F79009;
 }
 .cstm_black_border_badge.assigned .dot_holder {
     background: #C366CF;
 }
 .cstm_black_border_badge.damaged .dot_holder {
     background: #F04438;
 }
 .cstm_black_border_badge.decom .dot_holder {
     background: #667085;
 }
 .cstm_black_border_badge.use .dot_holder {
     background: #3468DC;
 }
 .cstm_black_border_badge.deployed .dot_holder {
     background: #DD6212;
 }
 .transparent_icon_btn {
     padding: 0 !important;
     min-width: auto !important;
 }
 .mr-4 {
     margin-right: 0.4rem !important;
 }
 .mr-12 {
     margin-right: 1.2rem !important;
 }
 .transparent_icon_btn.back_btn i {
     color: #667085;
     font-size: 24px;
 }
 .flexbox_holder {
     display: flex;
     flex-wrap: wrap;
     justify-content: space-between;
 }
 .each_filter_holder {
     display: flex;
     align-items: center;
     margin: 4px 0;
 }
 .each_filter_holder .cstm_select_formcontrol {
     min-width: 160px;
 }
 .each_filter_holder .cstm_select_formcontrol.min_width_auto { 
     min-width: 0;
 }
 .each_filter_holder .cstm_select_formcontrol input,
 .each_filter_holder .cstm_select_formcontrol .MuiSelect-select {
     padding: 13px;
 }
 .each_filter_holder .label_holder {
     font-family: "Inter", sans-serif;
     font-size: 12px;
     font-weight: 500;
     color: #1D2939;
     overflow: unset;
     padding-right: 16px;
 }
 .autocomplete_holder.extra_styling {
    box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12);
    border-radius: 4px;
    border: 1px solid #D0D5DD;
 }
 .MuiAutocomplete-popper ul.MuiAutocomplete-listbox,
 .autocomplete_holder ul,
 .select_opt_holder {
     max-height: 230px;
 }
 .autocomplete_paper_holder ul {
     margin-top: 50px;
 }
 .break_list_holder ul li {
     word-break: break-all;
 }
 .MuiFormHelperText-root {
     font-family: "Inter", sans-serif !important;
     font-size: 10px !important;
     background-color: transparent !important;
 }
 .assets_catalog_header + .sub_content_holder,
 input:not([type='checkbox']), textarea,
 .list_holder li,
 .MuiAutocomplete-popper ul.MuiAutocomplete-listbox li,
 .autocomplete_holder ul li ,
 .MuiPaper-root ul li,
 .Mui_listview_holder,
 .MuiSelect-select,
 .normal_txt_holder p,
 .MuiAutocomplete-tag {
     font-family: "Inter", sans-serif !important;
     font-size: 14px !important;
     font-weight: 400;
     color: #1D2939 !important;
     height: auto !important;
 }
 .MuiAutocomplete-tag {
     font-size: 12px !important;
 }
 .normal_txt_holder > span.MuiCheckbox-root {
     width: 13px;
 }
 .normal_txt_holder input[type="checkbox"] + svg {
     width: 17px;
     height: 17px;
     opacity: 0.75;
 }
 .MuiSelect-select {
     align-content: center;
 }
 .Mui_listview_holder {
     display: flex;
     align-items: center;
     padding: 5px 10px;
 }
 .cstm_dialog_modal .heading_holder .main_heading {
     font-family: "Archivo", sans-serif;
     font-weight: 600;
     font-size: 20px;
     color: #1D2939;
 }
 .cstm_dialog_modal .heading_holder .transparent_icon_btn .icon {
     font-size: 20px;
     color: #667085;
 }
 .cstm_dialog_modal .MuiDialog-paper {
     border-radius: 16px !important;
     /* done due to some scroll problem */
     overflow: hidden; 
 }
 .cstm_dialog_modal.medium .MuiDialog-paper {
     width: 765px;
     max-width: 765px;
 }
 .cstm_dialog_modal.small .MuiDialog-paper {
     width: 437px;
     max-width: 437px;
 }
 .cstm_dialog_modal .action_footer_holder {
     display: flex;
     padding: 0 24px 16px;
 }
 .cstm_dialog_modal .action_footer_holder.p-24 {
     padding: 0 24px 20px 24px !important;
 }
 .cstm_dialog_modal .action_footer_holder button {
     width: 100%;
 }
 .dot_status {
     display: inline-block;
     width: 8px;
     height: 8px;
     background: #12B76A;
     border-radius: 100%;
 }
 .dot_status.enabled {
     background: #12B76A;
 }
 .dot_status.disabled {
     background: #667085;
 }
 .rounded_select_holder {
     border-radius: 16px !important;
 }
 .rounded_select_holder .MuiSelect-select {
     min-height: auto !important;
     padding: 8px !important;
     padding-right: 16px !important;
 }
 @keyframes heartbeat
 {
   0%
   {
     transform: scale( .75 );
   }
   20%
   {
     transform: scale( 1 );
   }
   40%
   {
     transform: scale( .75 );
   }
   60%
   {
     transform: scale( 1 );
   }
   80%
   {
     transform: scale( .75 );
   }
   100%
   {
     transform: scale( .75 );
   }
 }
 .warning_content_holder .heading,
 .modal_heading {
     font-family: "Archivo", sans-serif;
     font-weight: 600;
     font-size: 20px;
     color: #1D2939;
     margin: 12px 0 8px;
 }
 .warning_content_holder .sub_content {
     font-family: "Inter", sans-serif;
     font-weight: 400;
     font-size: 14px;
     color: #1D2939;
 }
 .exclamation_icon_holder {
     width: 56px;
     height: 56px;
     border-radius: 50%;
     display: inline-flex;
     justify-content: center;
     align-items: center;
     background: #ff5050;
     color: #fff;
     animation: heartbeat 2s infinite;
 }
 .exclamation_icon_holder i {
     font-size: 40px;
     font-weight: 400;
 }
 .exclamation_icon_holder.red {
     background: #ff5050;
 }
 .exclamation_icon_holder.yellow {
     background: #ffca2a;
 }
 .exclamation_icon_holder.yellow i {
     color: #39484f;
 }
 .bell_icon_holder {
     width: 40px;
     height: 40px;
     display: inline-flex;
     justify-content: center;
     align-items: center;
     border: 1px solid #1D2939;
     border-radius: 8px;
     padding: 8px;
     cursor: pointer;
     position: relative;
 }
 .bell_icon_holder .icon {
     font-size: 22px;
     color: #1d2939;
 }
 .bell_icon_holder.active::after {
     content: '';
     width: 10px;
     height: 10px;
     background: #1d2939;
     border: 2px solid #fff;
     border-radius: 50%;
     position: absolute;
     top: 11px;
     right: 8px;
 }
 .list_popover_holder li a {
     min-width: 172px;
     display: flex;
     flex-wrap: wrap;
     justify-content: space-between;
     align-items: center;
     font-family: "Inter", sans-serif;
     font-weight: 500;
     font-size: 12px;
     color: #667085;
     padding: 8px;
     margin-bottom: 4px;
 }
 .list_popover_holder li:hover a {
     background: #f2f4f7;
     color: #1d2939;
     text-decoration: none;
 }
 .list_popover_holder li .count_holder {
     display: inline-block;
     border-radius: 50%;
     min-width: 20px;
     min-height: 20px;
     padding: 5px;
     background: #EAECF0;
     color: #667085;
     text-align: center;
     line-height: 1;
     box-sizing: content-box;
     white-space: nowrap;
 }
 .list_popover_holder li .count_holder::before {
     content: "";
     display: inline-block;
     vertical-align: middle;
     padding-top: 100%;
     height: 0;
 }
 .list_popover_holder li .count_holder .number {
     display: inline-block;
     vertical-align: middle;
 }
 .cstm_dropzone_holder {
     min-width: 600px;
     padding: 40px;
     border: 4px dashed #999;
     text-align: center;
     font-family: "Inter", sans-serif;
     font-weight: 500;
     font-size: 12px;
     color: #667085;
 }
 .fuse_light_bg {
     background-color: #fff !important;
 }
 .badge_info_holder {
     display: inline-flex;
     justify-content: space-between;
     align-items: center;
     border-radius: 9999px;
     background: #EAECF0;
     font-family: "Inter", sans-serif;
     font-weight: 500;
     font-size: 14px;
     color: #1D2939;
     padding: 8px 16px;
 }
 .badge_info_holder img + .text {
     padding-left: 8px;
     padding-right: 24px;
 }
 .grey_btn {
     min-width: auto !important;
     background-color: #475467 !important;
     border-radius: 4px !important;
     padding: 6px !important;
 }
 .grey_btn i {
     font-size: 14px;
     color: #fff;
 }
 .light_grey_btn {
     font-family: "Inter", sans-serif !important;
     font-weight: 500 !important;
     font-size: 12px !important;
     border-radius: 4px !important;
     padding: 8px 16px !important;
     background-color: #EAECF0 !important;
     color: #1D2939 !important;
     border: 1px solid #D0D5DD !important;
     text-transform: capitalize !important;
 }
 .table_responsive {
     width: 100%;
     overflow: auto;
 }
 .table_responsive table {
     width: 100%;
     font-family: "Inter", sans-serif;
 }
 .cstm_table_holder table {
     background: #fff;
 }
 .cstm_table_holder table thead tr th, 
 .conversion_holder .bottom_holder .content_holder table thead tr th  {
     text-align: left;
     font-weight: 600;
     font-size: 14px;
     background: #fcf6fd;
     color: #562658;
     border: 1px solid #EAECF0;
     padding: 16px 24px;
 }
 .cstm_table_holder table tbody tr td,
 .conversion_holder .bottom_holder .content_holder table tbody tr td {
     font-family: "Inter", sans-serif !important;
     font-weight: 500 !important;
     font-size: 12px !important;
     border: 1px solid #EAECF0;
 }
.conversion_holder .bottom_holder .content_holder table tbody tr td {
    padding: 16px 24px;
}
 .cstm_table_holder table tbody tr td .MuiSelect-select {
     font-family: "Inter", sans-serif !important;
     font-weight: 500 !important;
     font-size: 12px !important;
     padding-left: 24px;
 }
 .cstm_table_holder table tbody tr td:nth-child(1) {
     padding: 0 24px;
 }
 .cstm_table_holder table tbody tr:nth-child(even) td {
     background: #f9fafb;
 }
 .cstm_table_holder table tbody tr td .select_holder fieldset {
     border: none;
 }
 .checkbox_holder {
     padding-left: 8px;
 }
 .checkbox_holder .MuiFormControlLabel-label {
     font-family: "Inter", sans-serif !important;
     font-weight: 500 !important;
     font-size: 12px !important;
 }
 .react_dropzone_holder {
     border: 1px dashed #D0D5DD;
     border-radius: 8px;
     text-align: center;
     position: relative;
     padding-top: 20px;
 }
 .react_dropzone_holder .dzu-dropzone {
     min-height: 150px;
     border: none;
     z-index: 100;
 }
 .react_dropzone_holder .dzu-dropzone .dzu-inputLabel {
     padding-bottom: 100px;
 }
 .react_dropzone_holder.blank .outsource_holder {
     position: absolute;
     bottom: 20px;
     left: 50%;
     transform: translateX(-50%);
     z-index: -1;
 }
 .react_dropzone_holder .or_holder {
     font-family: "Inter", sans-serif !important;
     font-weight: 500 !important;
     font-size: 12px !important;
     color: #98A2B3;
     position: relative;
     margin: 20px 0;
 }
 .react_dropzone_holder .or_holder.after_or {
     display: inline-block;
 }
 .react_dropzone_holder .or_holder::before {
     content: '';
     width: 80px;
     height: 1px;
     background: #D0D5DD;
     position: absolute;
     left: -60px;
     top: 50%;
     transform: translateY(-50%);
 }
 .react_dropzone_holder .or_holder::after {
     content: '';
     width: 80px;
     height: 1px;
     background: #D0D5DD;
     position: absolute;
     right: -60px;
     top: 50%;
     transform: translateY(-50%);
 }
 .react_dropzone_holder .or_holder.after_or::before {
     left: -100px;
 }
 .react_dropzone_holder .or_holder.after_or::after {
     right: -100px;
 }
 .react_dropzone_holder .image {
     margin: 0 auto;
 }
 .react_dropzone_holder .loading_holder {
     text-align: center;
     margin-bottom: 50px;
 }
 .react_dropzone_holder .loading_holder img {
     margin: 0 auto;
     width: 138px;
 }
 .react_dropzone_holder .loading_holder .text_holder {
     color: #0B0B0B;
     font-family: "Inter", sans-serif !important;
     font-weight: 500 !important;
     font-size: 14px !important;
 }
 /* .react_dropzone_holder.filled .image, */
 /* .react_dropzone_holder.filled .outsource_holder, */
 .react_dropzone_holder.blank .temp_holder,
 .react_dropzone_holder.filled .temp_holder .or_holder {
     display: none;
 }
 .react_dropzone_holder.filled .outsource_holder .or_holder + button {
     cursor: not-allowed;
 }
 .react_dropzone_holder.blank .dzu-inputLabel,
 .react_dropzone_holder.filled .heading {
     display: block;
     color: #0B0B0B;
     font-family: "Inter", sans-serif !important;
     font-weight: 500 !important;
     font-size: 14px !important;
     margin-top: 12px;
 }
 
 .react_dropzone_holder.filled .headingDisable {
     color: #98A2B3;
 }
 
 .react_dropzone_holder .dzu-dropzone {
     width: calc(100% + 20px);
 }
 .react_dropzone_holder.filled .dzu-inputLabelWithFiles {
     font-family: "Inter", sans-serif !important;
     font-weight: 500 !important;
     font-size: 12px !important;
     border-radius: 4px !important;
     padding: 8px 16px !important;
     background-color: #EAECF0 !important;
     color: #1D2939 !important;
     border: 1px solid #D0D5DD !important;
     text-transform: capitalize !important;
     margin: 0 auto;
 }
 .react_dropzone_holder.blank .preview_holder {
     display: none;
 }
 .react_dropzone_holder.filled .preview_holder {
     width: 97%;
     margin: 0 auto 20px;
     display: block;
     text-align: left;
     padding: 16px;
     border: 1px solid #98A2B3;
     border-radius: 12px;
 }
 .react_dropzone_holder.filled .preview_holder .preview_span_holder {
     font-family: "Inter", sans-serif;
     font-weight: 500;
     font-size: 12px;
     color: #667085;
 }
 .react_dropzone_holder.filled .preview_holder .preview_span_holder .info_outer_holder {
     display: flex;
     flex-wrap: wrap;
     justify-content: space-between;
     align-items: flex-end;
     margin-bottom: 8px;
 }
 .react_dropzone_holder.filled .preview_holder .preview_span_holder .info_outer_holder .info_holder {
     width: calc(100% - 30px);
 }
 .react_dropzone_holder.filled .preview_holder .preview_span_holder .info_outer_holder .info_holder .inner_holder span {
     display: inline-block;
     position: relative;
 }
 .react_dropzone_holder.filled .preview_holder .preview_span_holder .info_outer_holder .info_holder .inner_holder span:not(:first-child) {
     padding-left: 15px;
 }
 .react_dropzone_holder.filled .preview_holder .preview_span_holder .info_outer_holder .info_holder .inner_holder span:not(:first-child)::before {
     content: '';
     width: 4px;
     height: 4px;
     background: #667085;
     border-radius: 100%;
     position: absolute;
     left: 6px;
     top: 50%;
     transform: translateY(-50%);
 }
 .react_dropzone_holder.filled .preview_holder .preview_span_holder .info_outer_holder .icon_holder i {
     font-size: 24px;
     color: #F04438;
     cursor: pointer;
 }
 .react_dropzone_holder.filled .preview_holder .preview_span_holder .info_outer_holder .info_holder .heading {
     font-family: "Inter", sans-serif !important;
     font-weight: 600 !important;
     font-size: 14px !important;
     color: #000;
     margin: 0;
 }
 .react_dropzone_holder.filled .preview_holder .preview_span_holder .progressbar_holder {
     width: 100%;
     height: 8px;
     border-radius: 9999px;
     background: #D0D5DD;
 }
 .react_dropzone_holder.filled .preview_holder .preview_span_holder .progressbar_holder .progress {
     border-radius: 9999px;
     background: #2B54CA;
 }
 .react_dropzone_holder.filled .preview_holder .dzu-previewContainer {
     display: block;
     padding: 5px;
 }
 .react_dropzone_holder.filled .preview_holder .dzu-previewContainer .dzu-previewStatusContainer progress {
     width: 100%;
 }
 .react_dropzone_holder .preview_holder .dzu-previewContainer .dzu-previewButton {
     filter: invert(21%) sepia(100%) saturate(7414%) hue-rotate(359deg) brightness(94%) contrast(117%)
 }
 progress::-webkit-progress-value { background: #2B54CA; }
 progress::-moz-progress-bar { background: #2B54CA; }
 progress { color: #2B54CA !important; }
 .white_card_holder {
     background: #fff;
     border-radius: 12px;
     padding: 24px;
 }
 div.ag-theme-alpine div.ag-row .ag-cell.cell_danger {
     background: #FFFAFA;
     color: #B42318 !important;
 }
 .cstm_dialog_modal .each_list_holder {
     display: flex;
     justify-content: space-between;
     align-items: center;
     padding: 12px;
     border: 1px solid #EAECF0;
     border-radius: 12px;
     margin-top: 16px;
     cursor: pointer;
 }
 .cstm_dialog_modal .each_list_holder .left_holder {
     display: flex;
     flex-wrap: wrap;
     align-items: center;
 }
 .cstm_dialog_modal .each_list_holder .left_holder .badge_container {
     min-width: 34px;
     min-height: 34px;
     width: 34px;
     height: 34px;
     display: inline-flex;
     justify-content: center;
     align-items: center;
     border-radius: 100%;
     margin-right: 8px;
 }
 .cstm_dialog_modal .each_list_holder .left_holder .badge_container.success_bg {
     background: #ECFDF3;
 }
 .cstm_dialog_modal .each_list_holder .left_holder .badge_container.danger_bg {
     background: #FEF3F2;
 }
 .cstm_dialog_modal .each_list_holder .left_holder .middle_container {
     font-family: "Inter", sans-serif;
     font-weight: 500;
     font-size: 13px;
     color: #475467;
 }
 .cstm_dialog_modal .each_list_holder .left_holder .middle_container span.success_text {
     font-size: 16px;
     font-weight: 600;
     color: #12B76A;
     margin-right: 4px;
 }
 .cstm_dialog_modal .each_list_holder .left_holder .middle_container span.danger_text {
     font-size: 16px;
     font-weight: 600;
     color: #F04438;
     margin-right: 4px;
 }
 .cstm_dialog_modal .each_list_holder .download_container {
     min-width: 32px;
     min-height: 32px;
     width: 32px;
     height: 32px;
     display: inline-flex;
     justify-content: center;
     align-items: center;
     background: #EAECF0;
     border-radius: 8px;
 }
 .cstm_dialog_modal .each_list_holder .download_container i {
     font-size: 16px;
     color: #475467;
 }
 .maxmin_btn {
     min-width: auto !important;
     width: 32px;
     height: 32px;
     border-radius: 100% !important;
     background: #fff !important;
     border: 1px solid #562658 !important;
     position: absolute !important;
     bottom: -8px;
     left: calc(50% + 40px);
     transform: translateX(-50%);
 }
 .maxmin_btn i {
     font-size: 16px;
     color: #562658 !important;
 }
 .zoomed_dialog_holder .MuiDialog-paper.MuiDialog-paperWidthXl {
     overflow: hidden;
     padding-bottom: 25px;
 }
 .zoomed_dialog_holder .maxmin_btn {
     bottom: 6px;
     left: 50%;
     transform: translateX(-50%);
 }
 .each_grey_border_holder {
     display: inline-block;
     border: 1px solid #EAECF0;
     border-radius: 8px;
     padding: 12px 20px;
     margin-bottom: 15px;
     margin-right: 15px;
 }
 .each_grey_border_holder .light_heading {
     font-family: "Inter", sans-serif;
     font-weight: 400;
     font-size: 12px;
     color: #667085;
 }
 .each_grey_border_holder .bottom_holder {
     display: inline-flex;
     align-items: center;
 }
 .each_grey_border_holder .bottom_holder .deep_heading,
 .conversion_holder .name_holder,
 .medium_heading {
     font-family: "Inter", sans-serif;
     font-weight: 500;
     font-size: 14px;
     color: #1D2939;
 }
 .each_grey_border_holder .bottom_holder i {
     font-size: 20px;
     padding-left: 5px;
 }
 .conversion_holder {
     width: 100%;
     padding: 16px 28px;
     border: 1px solid #EAECF0;
     border-radius: 16px;
     margin-top: 12px;
 }
 .conversion_holder.agent_holder {
     border-top-left-radius: 0;
 }
 .conversion_holder.user_holder {
     border-top-right-radius: 0;
 }
 .conversion_holder .top_holder {
     display: flex;
     flex-wrap: wrap;
     justify-content: space-between;
     align-items: center;
     margin-bottom: 16px;
 }
 .conversion_holder .top_holder .left_holder {
     display: inline-flex;
     align-items: center;
 }
 .conversion_holder .top_holder .left_holder .rounded_img_holder,
 .rounded_img_holder {
     display: inline-block;
     width: 30px;
     height: 30px;
     border-radius: 100%;
     background: #ccc;
     margin-right: 8px;
 }
 .conversion_holder .top_holder .left_holder .rounded_img_holder img,
 .rounded_img_holder img {
     width: 100%;
     height: 100%;
     object-fit: contain;
     border-radius: 100%;
 }
 .conversion_holder .top_holder .left_holder .name_holder {
     display: inline-block;
 }
 .conversion_holder .top_holder .left_holder .badge_holder {
     display: inline-block;
     background: #E9E9F6;
     color: #705D95;
     font-family: "Inter", sans-serif;
     font-weight: 500;
     font-size: 10px;
     line-height: 16px;
     padding: 3px 8px;
     border-radius: 9999px;
     margin-left: 4px;
 }
 .conversion_holder.agent_holder .top_holder .left_holder .badge_holder {
     background: #E9E9F6;
     color: #705D95;
 }
 .conversion_holder.user_holder .top_holder .left_holder .badge_holder {
     background: #DEEAFB;
     color: #2B54CA;
 }
 .conversion_holder .bottom_holder .content_holder {
     font-family: "Inter", sans-serif;
     font-weight: 400;
     font-size: 14px;
     color: #1D2939;
     margin: 0;
 }
 .conversion_holder .top_holder .right_holder {
     font-family: "Inter", sans-serif;
     font-weight: 400;
     font-size: 12px;
     color: #667085;
     position: relative;
 }
 .conversion_holder .top_holder .right_holder .time_holder {
     padding-left: 15px;
     position: relative;
 }
 .conversion_holder .top_holder .right_holder .time_holder::before {
     content: '';
     width: 6px;
     height: 6px;
     background: #667085;
     border-radius: 100%;
     margin-right: 5px;
     position: absolute;
     left: 5px;
     top: 4px;
 }
 .grey_table_holder table {
     border-collapse: separate;
     border-spacing: 0px;
     border: 1px solid #EAECF0;
     border-radius: 8px;
 }
 .grey_table_holder table thead tr th {
     font-family: "Inter", sans-serif;
     font-weight: 600;
     font-size: 12px;
     color: #1D2939;
     background: #F2F4F7;
     text-align: left;
     border: 1px solid #EAECF0;
     border-top: none;
     padding: 13px 24px;
 }
 .grey_table_holder table tbody tr td {
     font-family: "Inter", sans-serif;
     font-weight: 500;
     font-size: 12px;
     color: #1D2939;
     border: 1px solid #EAECF0;
     border-bottom: none;
     padding: 7px 24px;
 }
 .grey_table_holder table thead tr th:nth-child(1) {
     border-left: none;
     border-top-left-radius: 8px;
 }
 .grey_table_holder table thead tr th:last-child {
     border-right: none;
     border-top-right-radius: 8px;
 }
 .grey_table_holder table tbody tr:last-child td:nth-child(1) {
     border-left: none;
     border-bottom-left-radius: 8px;
 }
 .grey_table_holder table tbody tr:last-child td:last-child {
     border-right: none;
     border-bottom-right-radius: 8px;
 }
 .grey_table_holder table tbody tr td:nth-child(1) {
     border-left: none;
 }
 .grey_table_holder table tbody tr td:last-child {
     border-right: none;
 }
 .cstm_textarea {
     width: 100%;
     padding: 8px 16px;
     border: 1px solid #D0D5DD;
     border-radius: 4px;
     resize: none;
 }
 .cstm_textarea:focus {
    outline-color: #D0D5DD;
 }
 .cstm_dialog_modal .cstm_textarea::placeholder {
     font-family: "Inter", sans-serif;
     font-weight: 400;
     font-size: 14px;
     color: #98A2B3;
 }
 .cstm_dialog_modal .cstm_textarea,
 .cstm_label_holder {
     font-family: "Inter", sans-serif;
     font-weight: 400;
     font-size: 14px;
     color: #1D2939;
 }
 .grey_textarea_input {
     width: 100%;
     padding: 12.5px 14px;
     border: 1px solid #F2F4F7;
 }
 .primary_link_holder {
     color: #562658 !important;
 }
 .primary_link_holder i {
     font-size: 16px;
     padding-right: 4px;
 }
 .subject_holder .content_holder,
 .MuiMenu-list li {
     font-family: "Inter", sans-serif;
     font-weight: 500;
     font-size: 14px;
     color: #1D2939;
 }
 .cstm_badge_select_holder {
     border-radius: 9999px !important;
 }
 .cstm_badge_select_holder .MuiSelect-select {
     display: flex;
     padding: 4px 8px;
 }
 .cstm_badge_select_holder .MuiSelect-select .cstm_black_border_badge_holder {
     margin: 0;
     padding: 1px 0;
     background: transparent;
     color: #000;
     border: none;
 }
 .cstm_badge_select_holder fieldset {
     border-color: #000;
 }
 .cstm_black_border_badge_holder.cstm_select .MuiSelect-select,
 .cstm_black_border_badge_holder {
     display: inline-flex;
     align-items: center;
     font-family: "Inter", sans-serif !important;
     font-weight: 500 !important;
     font-size: 12px !important;
     color: #000000 !important;
     border: 1px solid #000;
     border-radius: 9999px;
     padding: 4px 8px;
 }
 .cstm_black_border_badge_holder.cstm_select .MuiSelect-select {
    padding-right: 20px !important;
 }
 .cstm_black_border_badge_holder.cstm_select {
    padding: 0;
    border: none;
 }
 .cstm_black_border_badge_holder.cstm_select fieldset {
    border: none;
 }
 .cstm_black_border_badge_holder.cstm_select .MuiSelect-select .select_label_holder,
 .MuiPopover-paper .MuiMenu-list li.cstm_menuitem_list .select_label_holder {
    display: inline-flex;
    align-items: center;
 }
 .MuiPopover-paper .MuiMenu-list li.cstm_menuitem_list .select_label_holder {
    font-family: "Inter", sans-serif !important;
    font-weight: 500 !important;
    font-size: 12px !important;
    color: #000000 !important;
    border: 1px solid #000;
    border-radius: 9999px;
    padding: 4px 8px;
 }
 .cstm_black_border_badge_holder.cstm_select .MuiSelect-select .select_label_holder .item_name_holder {
    display: inline-block;
    width: 90px;
    overflow: hidden;
    text-overflow: ellipsis;
 }
 .cstm_black_border_badge_holder.cstm_select.auto_width .MuiSelect-select .select_label_holder .item_name_holder {
    width: auto;
 }
 .MuiPopover-paper .MuiMenu-list li.cstm_menuitem_list .dot_holder,
 .cstm_black_border_badge_holder .dot_holder {
     display: inline-block;
     width: 8px;
     height: 8px;
     border-radius: 100%;
     background: #000;
     margin-right: 4px;
 }
 .cstm_black_border_badge_holder.open .dot_holder {
     background: #12B76A;
 }
 .cstm_black_border_badge_holder.in_progress .dot_holder {
     background: #F79009;
 }
 .cstm_black_border_badge_holder.pending .dot_holder {
     background: #C366CF;
 }
 .cstm_black_border_badge_holder.closed .dot_holder {
     background: #F04438;
 }
 .no_border_select_holder {
    font-weight: 500 !important;
}
 .no_border_select_holder .MuiSelect-select {
     display: inline-flex;
     align-items: center;
     font-family: "Inter", sans-serif;
     font-weight: 500;
     font-size: 14px;
     color: #1D2939;
     padding: 0;
 }
 .no_border_select_holder fieldset {
     border: none;
 }
 .MuiDialog-paperFullWidth .mapboxgl-popup {
     max-width: 280px !important;
     will-change: auto;
     transform: none !important;
     flex-direction: initial;
     height: 100%;
     left: 0;
     right: auto;
     z-index: 100;
     overflow: auto;
 }
 .MuiDialog-paperFullWidth .mapboxgl-popup .mapboxgl-popup-content {
     min-width: 280px;
     max-height: 100%;
     overflow: auto;
 }
 .MuiDialog-paperFullWidth .mapboxgl-popup .mapboxgl-popup-content h5 {
     width: 228px;
     font-size: 14px;
 }
 .MuiDialog-paperFullWidth .mapboxgl-popup-content input[type="checkbox"]:checked + span.icon + label {
     margin: 12px 0;
 }
 .MuiDialog-paperFullWidth .mapboxgl-popup .mapboxgl-popup-content > div ul li {
     padding: 6px 8px;
     padding-left: 18px;
     font-size: 12px;
 }
 .MuiDialog-paperFullWidth .mapboxgl-popup .mapboxgl-popup-content .mapboxgl-popup-close-button {
     display: none;
 }
 .overflow_hidden {
     overflow: hidden !important;
 }
 .overflow_auto {
     overflow: auto !important;
 }
 .scrollable_form_holder {
     max-height: calc(100vh - 130px);
     overflow: auto;
 }
 .scrollable_form_holder.submission_form .sidebar_btn_holder {
     width: 100%;
     background: #fff;
     padding: 14px;
     position: absolute;
     bottom: 0;
     left: 0;
 }
 .list_holder li {
     padding: 5px 0;
 }
 .location_list_holder li {
     position: relative;
     padding-left: 18px;
 }
 .location_list_holder li::before {
     content: '\eae8';
     font-family: "tabler-icons" !important;
     font-style: normal;
     font-weight: normal;
     position: absolute;
     left: 0;
     top: 6px;
 }
 .value_input input {
     padding: 18.5px 14px !important;
 }
 .main_autocomplete + div {
     top: 100%;
 }
 .inner_details_section .assetDetails_imgbox {
     display: flex;
     flex-direction: column;
     justify-content: center;
     align-items: center;
     width: 350px;
     max-width: calc(100% - 580px);
     height: 300px;
     margin: 0 auto;
     background: #fff;
     border: 1px solid #E9E9F6;
     border-radius: 16px;
 }
 .inner_details_section .assetDetails_imgbox .no_img_info {
     display: inline-block;
     font-family: "Inter", sans-serif;
     font-weight: 600;
     font-size: 30px;
     color: #b3b3b3;
 }
 .inner_details_section .assetDetails_imgbox img {
     width: 100%;
     height: 100%;
     object-fit: contain;
     border: 1px solid #E9E9F6;
     border-radius: 16px;
 }
 .inner_details_section .left_holder {
     display: flex;
     flex-wrap: wrap;
     width: calc(100% - 360px);
 }
 .inner_details_section .left_holder.img_included_holder .content_holder {
    width: calc(100% - 350px);
}
 .inner_details_section .left_holder .content_holder {
     width: 100%;
     align-content: center;
     padding-right: 25px;
 }
 .inner_details_section .left_holder .content_holder.align-top {
     align-content: normal;
 }
 .inner_details_section .left_holder .content_holder .status_badge_holder {
     display: inline-block;
     padding: 4px 8px;
     border: 1px solid #000;
     border-radius: 16px;
     font-family: "Inter", sans-serif;
     font-weight: 500;
     font-size: 12px;
     color: #000;
     margin: 3px 6px 3px 0;
 }
 .inner_details_section .left_holder .content_holder .status_badge_holder .dot_holder {
     display: inline-block;
     width: 8px;
     height: 8px;
     border-radius: 100%;
     margin-right: 4px;
 }
 .inner_details_section .left_holder .content_holder .name_loc_info_holder {
     display: flex;
     flex-wrap: wrap;
     padding: 28px 0;
 }
 .inner_details_section .left_holder .content_holder .name_loc_info_holder .share_btn {
     min-width: 36px;
     min-height: 36px;
     max-width: 36px;
     max-height: 36px;
     border-radius: 100%;
     display: inline-flex;
     justify-content: center;
     align-items: center;
 }
 .inner_details_section .left_holder .content_holder .name_loc_info_holder .share_btn i {
     font-size: 20px;
     color: #1D2939;
 }
 .extra_sub_heading .share_btn {
    min-width: 36px;
    min-height: 36px;
    max-width: 36px;
    max-height: 36px;
    background-color: #F2F4F7 !important;
    box-shadow: none !important;
    border-radius: 100%;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    margin: 0 4px;
    cursor: pointer;
 }
 .extra_sub_heading .share_btn i {
    font-size: 20px;
    color: #1D2939;
}

 .inner_details_section .left_holder .content_holder .name_loc_info_holder .main_page_heading {
     display: inline-block;
     padding-right: 6px;
 }
 .inner_details_section .left_holder .content_holder .name_loc_info_holder .orange_badge_holder {
     display: inline-block;
     max-width: 160px;
     white-space: nowrap;
     overflow: hidden;
     text-overflow: ellipsis;
     background: #FFEDE1;
     color: #DD6212;
     font-family: "Inter", sans-serif;
     font-weight: 500;
     font-size: 12px;
     border: 1px solid #DD6212;
     border-radius: 16px;
     padding: 2px 16px;
 }
 .inner_details_section .left_holder .content_holder .name_loc_info_holder .asset_address_text {
     width: 100%;
     display: inline-flex;
     /* align-items: center; */
     font-family: "Inter", sans-serif;
     font-weight: 500;
     font-size: 12px;
     color: #1D2939;
     margin: 4px 0;
 }
 .inner_details_section .left_holder .content_holder .name_loc_info_holder .desc_text {
     width: 100%;
     display: inline-flex;
     align-items: center;
     font-family: "Inter", sans-serif;
     font-weight: 400;
     font-size: 14px;
     color: #667085;
 }
.inner_details_section .left_holder .content_holder .name_loc_info_holder .asset_address_text .ti {
     display: inline-block;
     font-size: 16px;
     margin-right: 2px;
 }
.inner_details_section .left_holder .content_holder .infoStat_card_holder {
     display: flex;
     flex-wrap: wrap;
     gap: 12px;
 }
.inner_details_section .left_holder .content_holder .infoStat_card_holder .each_card {
     font-family: "Inter", sans-serif;
     padding: 12px 20px;
     border: 1px solid #EAECF0;
     border-radius: 8px;
 }
.inner_details_section .left_holder .content_holder .infoStat_card_holder .each_card .label_holder,
.cstm_label {
    font-family: "Inter", sans-serif;
    font-size: 12px;
    font-weight: 400;
    color: #667085;
    margin-bottom: 0;
 }
 .inner_details_section .left_holder .content_holder .infoStat_card_holder .each_card .name_holder {
     font-size: 14px;
     font-weight: 500;
     color: #1D2939;
     margin-bottom: 0;
 }
 .inner_details_section .map_location_holder {
     max-width: 360px;
     max-height: 300px;
     margin-left: auto;
     padding: 20px;
     border: 1px solid #EAECF0;
     border-radius: 16px;
     background: #fff;
 }
 .inner_details_section .map_location_holder .asset_address_text {
     display: inline-flex;
     font-family: "Inter", sans-serif;
     font-weight: 500;
     font-size: 12px;
     color: #1D2939;
     margin-bottom: 20px;
 }
 .back_btn_icon {
     display: inline-block;
     margin-bottom: 12px;
 }
 .back_btn_icon:hover {
     text-decoration: none !important;
 }
 .back_btn_icon i {
     font-size: 24px;
     color: #667085;
 }
 .cstm_aggridtable_holder {
     width: 100%;
     overflow: auto;
 }
 .cstm_aggridtable_holder table {
     width: 100%;
     font-family: "Inter", sans-serif;
     font-size: 12px;
     color: #1D2939;
     border-collapse: separate;
     border-spacing: 0;
 }
 .cstm_aggridtable_holder table thead tr th {
     text-align: left;
     background: #F2F4F7;
     font-weight: 600;
     padding: 14px;
     border: 1px solid #EAECF0;
 }
 .cstm_aggridtable_holder table thead tr th:first-child {
     border-top-left-radius: 8px;
 }
 .cstm_aggridtable_holder table thead tr th:last-child {
     border-top-right-radius: 8px;
 }
 .cstm_aggridtable_holder table tbody tr td {
     text-align: left;
     background: transparent;
     font-weight: 500;
     padding: 10px 14px;
     border: 1px solid #EAECF0;
     border-right: 1px solid #F2F4F7;
 }
 .cstm_aggridtable_holder table tbody tr:last-child td:first-child {
     border-bottom-left-radius: 8px;
 }
 .cstm_aggridtable_holder table tbody tr:last-child td:last-child {
     border-bottom-right-radius: 8px;
 }
 .no_data_holder {
     font-family: "Inter", sans-serif;
     text-align: center;
 }
 .no_data_holder img {
     margin: 0 auto;
 }
 .no_data_holder .bold_txt {
     font-size: 18px;
     font-weight: 600;
     color: #98A2B3;
     margin: 4px 0;
 }
 .no_data_holder .normal_txt {
     font-family: "Inter", sans-serif;
     font-size: 14px;
     font-weight: 400;
     color: #475467;
 }
.cstm_breadcrumbs_holder {
    padding: 28px 0 16px;
}
.cstm_breadcrumbs_holder nav ol li {
    font-family: "Inter", sans-serif !important;
    font-size: 12px !important;
    color: #1d2939;
    text-transform: capitalize;
}
.cstm_breadcrumbs_holder nav ol li a {
    font-family: "Inter", sans-serif !important;
    font-size: 12px !important;
    color: #667085;
}
.cstm_black_border_badge_holder {
    display: inline-flex;
    align-items: center;
    font-family: "Inter", sans-serif;
    font-weight: 500;
    font-size: 12px;
    /* border: 1px solid #000; */
    border-radius: 9999px;
    padding: 4px 8px;
}
.cstm_black_border_badge_holder .dot_holder {
    display: inline-block;
    width: 8px;
    height: 8px;
    border-radius: 100%;
    margin-right: 4px;
}
.list_search_holder {
    width: 360px;
    min-width: 360px;
    max-width: 360px;
    border-right: 1px solid #EAECF0;
}
.list_search_holder .search_holder {
    border: 1px solid #EAECF0;
    border-radius: 8px;
    padding: 13px 16px;
    margin: 0 20px 20px;
}
.list_search_holder .search_holder input {
    width: calc(100% - 18px);
    border: none;
    padding: 0;
}
.list_search_holder .search_holder i {
    font-size: 16px;
}
.list_search_holder .listing_holder {
    height: calc(100vh - 170px);
    overflow: auto;
}
.list_search_holder .listing_holder .each_list_holder {
    padding: 12px 20px;
    cursor: pointer;
}
.list_search_holder .listing_holder .each_list_holder:hover,
.list_search_holder .listing_holder .each_list_holder.active {
    background: #F2F4F7;
}
.list_search_holder .listing_holder .each_list_holder:not(:last-child) {
    border-bottom: 1px solid #EAECF0;
}
.list_search_holder .listing_holder .each_list_holder .id_holder {
    font-family: "Inter", sans-serif;
    font-weight: 500;
    font-size: 12px;
    color: #667085;
    margin-bottom: 2px;
    cursor: pointer;
}
.list_search_holder .listing_holder .each_list_holder .heading_holder {
    font-family: "Inter", sans-serif;
    font-weight: 600;
    font-size: 14px;
    color: #1D2939;
    cursor: pointer;
}
.list_search_holder .listing_holder .each_list_holder .content_holder {
    font-family: "Inter", sans-serif;
    font-weight: 400;
    font-size: 12px;
    color: #667085;
    cursor: pointer;
}
.list_content_holder {
    width: calc(100% - 360px);
    padding: 12px 24px;
}
.list_content_holder .top_heading_holder {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
}
.list_content_holder .top_heading_holder .main_page_heading {
    display: inline-flex;
    align-items: center;
}
.list_content_holder .top_heading_holder .main_page_heading i {
    color: #667085;
    cursor: pointer;
    transform: rotate(0deg);
    transition: all 0.5s ease-in-out;
}
.list_content_holder .top_heading_holder .main_page_heading.active i {
    transform: rotate(180deg);
    transition: all 0.5s ease-in-out;
}
.list_content_holder .top_heading_holder .extra_sub_heading {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    background: #ECFDF3;
    color: #027A48;
    font-family: "Inter", sans-serif;
    font-weight: 500 !important;
    font-size: 12px !important;
    border: 1px solid #6CE9A6;
    border-radius: 16px;
    padding: 2px 8px;
    line-height: 15px;
    margin: 0 4px;
}
.list_content_holder .top_heading_holder + .sub_content_holder {
    max-width: 600px;
    padding-left: 35px;
    padding-bottom: 20px;
}
.list_content_holder .top_heading_holder .back_btn {
    margin-right: 12px !important;
}
.list_content_holder .top_heading_holder .btns_holder button {
    line-height: normal !important;
    padding: 4px 16px !important;
}
.list_content_holder .top_heading_holder .btns_holder button:first-child {
    margin-right: 8px !important;
}
.gradient_box_holder {
    position: fixed;
    width: 100%;
    height: 80px;
    text-align: center;
    align-content: center;
    top: 0;
    left: 0;
    background: linear-gradient(180deg, rgba(0,0,0,0.4) 0%, rgba(0,0,0,0) 100%);
    z-index: 21;
}
.alert_holder {
    background: #fff;
    color: #1D2939;
    padding: 8px 12px;
    border: 1px solid #D0D5DD;
    border-radius: 4px;
    font-family: "Inter", sans-serif;
    font-weight: 600;
    font-size: 12px;
    display: inline-flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
}
.gradient_animated_button {
    min-width: auto !important;
    background: linear-gradient(-56deg, rgba(122,69,125,1) 0%, rgba(51,79,152,1) 100%);
    /* background: 
        linear-gradient(-56deg, rgba(122,69,125,1) 0%, rgba(51,79,152,1) 100%) padding-box,
        linear-gradient(60deg, #00f, #00e8ff) border-box; */
    color: #fff !important;
    background-size: 600%;
    animation: anime 2s linear infinite;
    font-family: "Inter", sans-serif !important;
    font-weight: 500 !important;
    font-size: 12px !important;
    padding: 4px 6px !important;
    border-radius: 4px !important;
    line-height: normal !important;
    text-transform: capitalize !important;
    position: relative;
    z-index: 10;
}
.gradient_animated_button::before {
    content: '';
    width: 100%;
    height: 100%;
    background: linear-gradient(-56deg, rgba(122,69,125,1) 0%, rgba(51,79,152,1) 100%);
    background-size: 600%;
    animation: anime 2s linear infinite;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 4px;
    z-index: -1;
}
.gradient_animated_button::after {
    content: '';
    width: calc(100% + 4px);
    height: calc(100% + 4px);
    background: linear-gradient(-51deg, rgba(252,191,255,1) 0%, rgba(186,205,255,1) 100%);
    position: absolute;
    top: -2px;
    left: -2px;
    border-radius: 4px;
    z-index: -2;
}
@keyframes anime {
    0% {
        background-position: 0% 50%;
    }
   50% {
       background-position: 100% 50%;
   }
   100% {
       background-position: 0% 50%;
   }
}
.focus_mode_btn {
    max-width: 122px;
    padding: 12px 14px !important;
    /* float: right; */
}
.focus_mode_btn i.ti-bolt {
    font-size: 16px;
    margin-right: 4px;
}
.focus_mode_btn::before {
    background: linear-gradient(-76deg, rgba(122,69,125,1) 0%, rgba(51,79,152,1) 100%);
    background-size: 600%;
    animation: anime 2s linear infinite;
}
.focus_mode_btn::after {
    background: linear-gradient(-73deg, rgba(252,191,255,1) 0%, rgba(186,205,255,1) 100%);
}
.top_bottom_btn {
    position: fixed;
    right: 50px;
    bottom: 15px;
    background: #F8ECFB;
    color: #562658;
    border: 1px solid #562658;
    width: 30px;
    height: 30px;
    border-radius: 100%;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    font-weight: 700;
    animation: bounce 2s infinite;
    cursor: pointer;
}
@keyframes bounce {
    0%, 20%, 50%, 80%, 100% {
      transform: translateY(0);
    }
    40% {
      transform: translateY(-30px);
    }
    60% {
      transform: translateY(-15px);
    }
}
   

.orange_badge_holder {
    display: inline-block;
    background: #fffefe;
    color: #F9B84B;
    font-family: "Inter", sans-serif;
    font-weight: 500;
    font-size: 10px;
    border: 1px solid #F9B84B;
    border-radius: 16px;
    padding: 1px 10px;
}

.singleAttachment {
    width: 100%;
    border: 1px solid #D0D5DD;
    border-radius: 4px;
    padding: 4px 8px;
    margin: 4px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.singleAttachment .ti-xbox-x {
    cursor: pointer;
    color: #ff0000;
}
.textfield_select_holder .MuiSelect-select:focus {
    background-color: transparent !important;
}
.start_end_text_input .text_holder {
    font-family: "Inter", sans-serif !important;
    font-weight: 400 !important;
    font-size: 14px !important;
    color: #1d2939;
}
.listing_status_badge_holder {
    font-family: "Inter", sans-serif;
    font-weight: 500;
    font-size: 12px;
    /* display: inline-block; */
    padding: 4px 16px;
    border-radius: 16px;
}
.listing_status_badge_holder.active { 
    background: #ECFDF3;
    color: #027A48;
    border: 1px solid #6CE9A6;
}
.listing_status_badge_holder.inactive { 
    background: #FEF3F2;
    color: #F04438;
    border: 1px solid #FDA29B;
}
.purple_icon_holder {
    background: #DFD3FF;
    color: #7B5CCC;
}
.deepyellow_icon_holder {
    background: #FAD58F;
    color: #DD950E;
}
.skyblue_icon_holder {
    background: #ABEFFE;
    color: #0B7B94;
}
.yellow_icon_holder {
    background: #FFE5B6;
    color: #DD6212;
}
.red_icon_holder {
    background: #FDCCCB;
    color: #ED4B46;
} 

.cstm_autoselect_holder .MuiAutocomplete-input,
.cstm_autoselect_holder .MuiSelect-select {
    padding: 12px 16px;
}
.cstm_autoselect_holder fieldset {
    border-color: #D0D5DD;
}
.MuiAutocomplete-popper .truncated_list_item_holder ul li {
    display: block;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.user_profile_holder {
    display: flex;
    background: #fff;
}
.user_profile_holder .left_holder {
    width: 330px;
    background: #FCFCFD;
    border-right: 1px solid #EAECF0;
}
.user_profile_holder .left_holder .top_user_info_holder {
    padding: 24px;
    border-bottom: 1px solid #EAECF0;
}
.user_profile_holder .left_holder .top_user_info_holder .image_container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;
}
.user_profile_holder .left_holder .top_user_info_holder .image_container .profile_image {
    width: 107px;
    height: 107px;
    border-radius: 100%;
    object-fit: contain;
}
.user_profile_holder .left_holder .top_user_info_holder .image_container .id_holder {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    background: #FFEDE1;
    color: #DD6212;
    font-family: "Inter", sans-serif;
    font-weight: 500;
    font-size: 12px;
    padding: 0 8px;
    border: 1px solid #DD6212;
    border-radius: 16px;
}
.user_profile_holder .left_holder .top_user_info_holder .user_name_holder {
    font-family: "Archivo", sans-serif;
    font-size: 20px;
    font-weight: 600;
    color: #1d2939;
    margin: 8px 0;
}
.user_profile_holder .left_holder .top_user_info_holder .user_badge_holder {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    margin-bottom: 20px;
}
.user_profile_holder .left_holder .top_user_info_holder .user_badge_holder .each_badge {
    font-family: "Inter", sans-serif;
    font-weight: 500;
    font-size: 12px;
    border-radius: 9999px;
    min-width: 50px;
    text-align: center;
    padding: 6px 10px;
}
.user_profile_holder .left_holder .top_user_info_holder .user_badge_holder .each_badge.job_holder {
    background: #EAECF0;
    color: #1d2939;
}
.user_profile_holder .left_holder .top_user_info_holder .user_badge_holder .each_badge.role_holder {
    background: #FEF0C7;
    color: #DC6803;
}
.user_profile_holder .left_holder .bottom_user_info_holder {
    padding: 32px 24px;
}
.user_profile_holder .left_holder .bottom_user_info_holder .each_info_holder {
    display: flex;
    flex-wrap: wrap;
    gap: 12px;
}
.user_profile_holder .left_holder .bottom_user_info_holder .each_info_holder:not(:last-child) {
    margin-bottom: 24px;
}
.user_profile_holder .left_holder .bottom_user_info_holder .each_info_holder .icon {
    font-size: 16px;
    color: #667085;
}
.user_profile_holder .left_holder .bottom_user_info_holder .each_info_holder .information_holder {
    width: calc(100% - 30px);
}
.user_profile_holder .left_holder .bottom_user_info_holder .each_info_holder .information_holder .label_holder {
    font-family: "Inter", sans-serif;
    font-weight: 500;
    font-size: 12px;
    color: #667085;
    margin-bottom: 4px;
}
.user_profile_holder .left_holder .bottom_user_info_holder .each_info_holder .information_holder .info_holder {
    font-family: "Inter", sans-serif;
    font-weight: 500;
    font-size: 14px;
    color: #1D2939;
}
.user_profile_holder .right_holder {
    width: calc(100% - 330px);
    padding: 28px;
}

.cstm_input_container input {
    padding: 5px 10px;
    width: 100%;
    border: 1px solid #EAECF0 !important;
    border-radius: 8px;
    font-family: "Inter", sans-serif;
    font-weight: 500;
    font-size: 12px;
}
.cstm_input_container input::placeholder {
    font-family: "Inter", sans-serif;
    font-weight: 500;
    font-size: 12px;
    color: #98A2B3;
}

.uploaded_content_holder {
    display: flex;
    flex-wrap: wrap;
    gap: 4px;
}
.uploaded_content_holder > div,
.uploaded_content_holder .attachment_name_holder {
    display: inline-flex;
    padding: 8px 12px;
    border: 1px solid #EAECF0;
    border-radius: 9999px;
    cursor: pointer;
}
.uploaded_content_holder > div a,
.uploaded_content_holder .attachment_name_holder {
    font-family: "Inter", sans-serif !important;
    font-size: 12px;
    font-weight: 500;
    background-color: transparent !important;
    color: #1d2939 !important;
    border-bottom: none !important;
    cursor: pointer;
}

.mui_label {
    display: inline-block;
    white-space: normal;
    word-break: break-all;
    max-width: 160px
}
 
.conversion_holder .top_holder .left_holder .information_holder {
    position: relative;
}
.conversion_holder .top_holder .left_holder .information_holder .upper_holder {
    margin-bottom: 4px;
}
.conversion_holder .top_holder .left_holder .information_holder .lower_holder {
    display: flex;
    flex-wrap: wrap;
    align-content: center;
}
.conversion_holder .top_holder .left_holder .information_holder .lower_holder .each_info {
    font-family: "Inter", sans-serif;
    font-size: 12px;
    color: #1D2939;
    align-content: center;
}
.conversion_holder .top_holder .left_holder .information_holder .lower_holder .each_info:first-child {
    padding-right: 8px;
}
.conversion_holder .top_holder .left_holder .information_holder .lower_holder .each_info:not(:first-child) {
    padding: 0 8px;
    border-left: 1px solid #98A2B3;
}
.conversion_holder .top_holder .left_holder .information_holder .lower_holder .each_info .label_holder {
    color: #667085;
    padding-right: 4px;
}
.conversion_holder .top_holder .left_holder .information_holder .lower_holder .each_info .num_badge {
    display: inline-block;
    background: #EAECF0;
    color: #1d2939;
    font-family: "Inter", sans-serif;
    font-size: 12px;
    font-weight: 500;
    border-radius: 4px;
    padding: 4px 8px;
    margin-left: 4px;
    cursor: pointer;
}
.conversion_holder .top_holder .left_holder .information_holder .user_dropdown_holder {
    position: absolute;
    /* right: 0; */
    top: 100%;
    padding: 10px;
    background: #fff;
    border: 1px solid #ddd;
    border-radius: 4px;
    box-shadow: 0 4px 40px 0 rgba(0, 0, 0, 0.08);
    z-index: 1;
    display: none;
}
.conversion_holder .top_holder .left_holder .information_holder .user_dropdown_holder.show {
    display: block;
}
.conversion_holder .top_holder .left_holder .information_holder .user_dropdown_holder .each_user {
    display: flex;
    gap: 4px;
    align-items: center;
    font-family: "Inter", sans-serif;
    font-size: 13px;
    color: #1d2939;
    padding: 8px;
}

.cstm_multi_select_dropdown {
    position: relative;
    width: 100%;
    font-family: "Inter", sans-serif;
}
.cstm_multi_select_dropdown .cstm_input_outer_holder {
    display: flex;
    flex-wrap: wrap;
    padding: 12px;
    border: 1px solid #EAECF0;
    border-radius: 4px;
    gap: 10px;
}
.cstm_multi_select_dropdown .cstm_input_outer_holder .chip_container {
    display: inline-flex;
    flex-wrap: wrap;
    gap: 5px;
}
.cstm_multi_select_dropdown .cstm_input_outer_holder .chip_container .chip_holder {
    background: #e9eaeb;
    color: #1d2939;
    font-family: "Inter", sans-serif;
    font-size: 14px;
    padding: 5px 10px;
    border-radius: 16px;
    display: inline-flex;
    white-space: nowrap;
    align-items: center;
}
.cstm_multi_select_dropdown .cstm_input_outer_holder .chip_container .chip_holder .close-btn {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 22px;
    height: 22px;
    border-radius: 100%;
    background: #000;
    color: #fff;
    margin-left: 8px;
    cursor: pointer;
}
.cstm_multi_select_dropdown .cstm_input_outer_holder .input_box {
    width: 0;
    min-width: 30px;
    flex-grow: 1;
    text-overflow: ellipsis;
    padding: 0;
    box-sizing: border-box;
}
.cstm_multi_select_dropdown .cstm_dropdown_holder {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    max-width: 316px;
    max-height: 350px;
    padding: 10px;
    background: white;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 4px 40px 0 rgba(0, 0, 0, 0.08);
    border-radius: 4px;
    z-index: 10;
}
.cstm_multi_select_dropdown .cstm_dropdown_holder .list_holder {
    max-height: 250px;
    overflow: auto;
}
.cstm_multi_select_dropdown .cstm_dropdown_holder .list_holder li {
    display: flex;
    flex-wrap: wrap;
    padding: 8px 14px;
    cursor: pointer;
}
.cstm_multi_select_dropdown .cstm_dropdown_holder .list_holder li:hover {
    background: #f0f0f0;
}
.cstm_multi_select_dropdown .cstm_dropdown_holder .list_holder li .info_holder .name_holder {
    font-size: 14px;
    font-weight: 500;
    color: #1D2939;
}
.cstm_multi_select_dropdown .cstm_dropdown_holder .list_holder li .info_holder .mail_holder {
    font-size: 12px;
    font-weight: 400;
    color: #666b70;
}
.cstm_multi_select_dropdown .cstm_dropdown_holder .btn_holder {
    display: flex;
    gap: 12px;
    padding: 0 14px;
    margin-top: 16px;
}
.cstm_multi_select_dropdown .cstm_dropdown_holder .btn_holder button {
    width: 100%;
}
.forward_arrow_holder {
    width: 24px;
    height: 24px;
    justify-content: center;
    align-items: center;
    background: #EAECF0;
    border: 1px solid #d0d5dd;
    border-radius: 4px;
    display: none;
    cursor: pointer;
}
.forward_arrow_holder i {
    width: 16px;
    font-size: 16px;
    color: #475467;
}
.conversion_holder:hover .right_holder .forward_arrow_holder {
    display: inline-flex;
}
.see_more_content_holder {
    display: none;
}
.see_more_content_holder.show, #main_conversation .see_more_btn {
    display: block !important;
}

#main_conversation .see_more_btn {
    background: #e8eaed !important;
    color: #000;
    font-weight: 600;
    padding: 2px 6px;
    border-radius: 4px;
    cursor: pointer;
}

 @media (max-width: 1360px) {
     .inner_details_section .left_holder {
         width: 100%;
     }
     .inner_details_section .map_location_holder {
         width: 100%;
         max-width: 100%;
         margin-top: 20px;
     }
 }
 @media (max-width: 991px) {
     .each_cat_card {
         width: 200px;
     }
     .inner_details_section .left_holder .content_holder {
         width: 100%;
         text-align: center;
         padding: 28px 0;
     }
 }
 @media (max-height: 800px) {
     .MuiDialog-paperFullWidth .mapboxgl-popup .mapboxgl-popup-content {
         max-height: calc(100vh - 110px);
     }
 }